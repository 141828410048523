// Import Libraries
import React, {
  Fragment,
  createRef,
  useContext,
  useEffect,
  useState,
} from "react";
import PropTypes from "prop-types";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
import Dropzone from "react-dropzone";
import fileDownload from "js-file-download";
// Import Custom Components
import SchoolCycle from "../../components/global/form/SchoolCycle";
import CreateCycleModal from "../cycles/modals/CreateCycleModal";
import TextLoading from "../../components/global/TextLoading";
import ModalSuccess from "../../components/global/modals/ModalSuccess";
import { ImportProspects } from "../../api/Crm";
// Import Contexts
import { MainContext } from "../../App";

// Custom styles section
const stylesCustom = {
  modalNote: {
    fontWeight: "bold",
    fontFamily: "'Open Sans', sans-serif",
    fontSize: "12px",
    margin: "15px 0px 10px 0px",
  },
  dropzone: {
    position: "relative",
    height: "auto",
    borderWidth: "2px",
    borderColor: "rgb(194, 194, 194)",
    borderStyle: "dashed",
    borderRadius: "5px",
  },
  dropzoneText: {
    fontWeight: "normal",
    fontFamily: "'Open Sans', sans-serif",
    fontSize: "14px",
    color: "#ea2c54",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

export default function ModalImportProspects(props) {
  const { permissions } = useContext(MainContext);
  const [t] = useTranslation(["crm", "global"]);
  const dropzoneRef = createRef();
  const {
    openButtonName = t("crm:importProspects.import"),
    modalTitle = t("crm:importProspects.importModalTitle"),
    modalDescription = t("crm:importProspects.importModalDescription"),
    modalNote = t("crm:importProspects.importModalNote"),
    buttonDownloadTemplateName = t(
      "crm:importProspects.importDownloadTemplate"
    ),
    downloadTemplate = () => {},
  } = props;
  const [open, setOpen] = useState(false);
  const [schoolCycle, setSchoolCycle] = useState("");
  const [reloadCycles, setReloadCycles] = useState(false);
  const [openCreateCycleModal, setCreateCycleModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDowloadingTemplate, setIsDowloadingTemplate] = useState(false);
  const [isProcessingCSV, setIsProcessingCSV] = useState(false);
  const [file, setFile] = useState("");
  const [showSuccessImportModal, setShowSuccessImportModal] = useState(false);
  const [error, setError] = useState("");

  /**
   * Initial loading
   */
  useEffect(() => {
    Sentry.setTag("section", "Import Student Modal");
  }, []);

  /**
   * Open File
   */
  const openFile = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  /**
   * save file csv
   * @param {File} file
   */
  const saveFile = (file) => {
    if (file.length) {
      setFile(file);
    }
  };

  /**
   * Description: Close the Import Prospects Modal
   */
  const closeImportProspectsModal = () => {
    setOpen(false);
    setFile("");
    setSchoolCycle("");
    setError("");
  };

  /**
   * Description: Function to download the template to import prospects
   */
  const downloadImportTemplate = () => {
    setIsSaving(true);
    setIsDowloadingTemplate(true);
    downloadTemplate()
      .then((result) => {
        if (result.description) {
          Sentry.captureException(Error(JSON.stringify(result)));
          return;
        }
        fileDownload(result.data, "template.csv");
      })
      .catch(() => {})
      .finally(() => {
        setIsSaving(false);
        setIsDowloadingTemplate(false);
      });
  };

  /**
   * Description: Function to import csv file
   */
  const importCSV = () => {
    setIsSaving(true);
    setIsProcessingCSV(true);
    setError("");
    ImportProspects(file[0], schoolCycle.id)
      .then((result) => {
        if (result?.description) {
          Sentry.captureException(Error(JSON.stringify(result)));
          setError(result.description);
        } else {
          closeImportProspectsModal();
          setShowSuccessImportModal(true);
        }
      })
      .finally(() => {
        setIsSaving(false);
        setIsProcessingCSV(false);
      });
  };

  return (
    <Fragment>
      {/**Open modal buttton */}
      <Button
        id="openModalImport"
        variant="outline-secondary"
        onClick={() => setOpen(true)}
        style={{ marginRight: "10px" }}
        disabled={props.thereAreNoCycles}
      >
        {openButtonName}
      </Button>
      {/** Prospects Import Modal */}
      {open && (
        <Modal
          show={open && !openCreateCycleModal}
          aria-labelledby="contained-modal-title-lg"
        >
          {/** Modal Title */}
          <Modal.Header className="modal-header">
            <Modal.Title className="modal-title">{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <Row>
                {/** Description */}
                <Col xs={7}>{modalDescription}</Col>
                {/** Download template button */}
                <Col xs={5}>
                  <Button
                    id="downloadImportTemplate"
                    className="float-end"
                    variant="primary"
                    onClick={downloadImportTemplate}
                    disabled={isSaving}
                  >
                    {isDowloadingTemplate ? (
                      <TextLoading
                        text={t("global:buttons.downloading")}
                        variant="light"
                      />
                    ) : (
                      buttonDownloadTemplateName
                    )}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs={8}>
                  <p style={stylesCustom.modalNote}>{modalNote}</p>
                </Col>
              </Row>
              {/** School Cycle Select */}
              <Row>
                <Col xs={12}>
                  <Form.Label>
                    {t("crm:importProspects.importSchoolCycle")}
                  </Form.Label>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col xs={8}>
                  <SchoolCycle
                    useIn="filters"
                    handleOnchange={(cycle) => setSchoolCycle(cycle)}
                    reloadCycles={reloadCycles}
                    value={schoolCycle}
                  />
                </Col>
                {/** Create School cycle button */}
                {permissions.config_cycle.create && (
                  <Col xs={4} className="text-end">
                    <Button
                      id="addCycleBtn"
                      variant="primary"
                      onClick={() => setCreateCycleModal(true)}
                      style={{ height: "-webkit-fill-available" }}
                      disabled={isSaving}
                    >
                      {t("crm:importProspects.ImportAddSchoolCycle")}
                    </Button>
                  </Col>
                )}
              </Row>
              {/** Load Import Prospects template */}
              <div style={stylesCustom.dropzone}>
                <Dropzone
                  ref={dropzoneRef}
                  accept=".csv, .xlsx, .xls"
                  onDrop={saveFile}
                  noClick
                  noKeyboard
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      className="text-center"
                      id="dropzoneCsv"
                      style={stylesCustom.dropzoneText}
                      {...getRootProps()}
                    >
                      <div id="fileInformation">
                        <p className="mb-3" id="file Name">
                          {file[0]?.name ||
                            t("crm:importProspects.uploadFilePlaceholder")}
                          <br />
                          {file ? "" : t("global:importCSV.dropFileOr")}
                        </p>
                      </div>
                      <input {...getInputProps()} />
                      <Button
                        id="uploadStudentInformationFileBtn"
                        type="button"
                        className="btn btn-text btn-white"
                        disabled={isSaving}
                        onClick={openFile}
                      >
                        {t("global:importCSV.uploadFile")}
                      </Button>
                    </div>
                  )}
                </Dropzone>
              </div>
            </form>
            {error && (
              <Alert className="mt-3 mb-0" variant="warning">
                {error}
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div id="actionBtns">
              {/** Close button */}
              <Button
                id="cancelImportCsvStudentBtn"
                style={{ marginRight: "10px" }}
                variant="outline-secondary"
                disabled={isSaving}
                onClick={closeImportProspectsModal}
              >
                {t("global:buttons.cancel")}
              </Button>
              {/** Save button */}
              <Button
                id="importCsvStudentBtn"
                variant="primary"
                onClick={importCSV}
                disabled={isSaving || !schoolCycle || !file}
              >
                {isProcessingCSV ? (
                  <TextLoading
                    text={t("global:buttons.importing")}
                    variant="light"
                  />
                ) : (
                  t("global:buttons.import")
                )}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {/** Create School Cycle Modal */}
      {openCreateCycleModal && (
        <CreateCycleModal
          open={openCreateCycleModal}
          importView={"cycle"}
          handleClose={() => setCreateCycleModal(false)}
          getSchoolCycleFromComponent={() => setReloadCycles(true)}
        />
      )}
      {/** Prospects Import Processing Modal */}
      {showSuccessImportModal && (
        <ModalSuccess
          showModalSuccess={showSuccessImportModal}
          title={t("global:buttons.processing")}
          message={t("crm:importProspects.proccessImportMessage")}
          fnAcceptButton={() => setShowSuccessImportModal(false)}
        />
      )}
    </Fragment>
  );
}

ModalImportProspects.propTypes = {
  openButtonName: PropTypes.string,
  modalTitle: PropTypes.string,
  modalDescription: PropTypes.string,
  modalNote: PropTypes.string,
  buttonDownloadTemplateName: PropTypes.string,
  downloadTemplate: PropTypes.func,
  thereAreNoCycles: PropTypes.bool,
};
