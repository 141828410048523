import React, { useState, useRef, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  Container,
  Tab,
  Nav,
  Col,
  Button,
  Row,
  Dropdown,
  FormCheck,
} from "react-bootstrap";
import * as moment from "moment";
import * as Sentry from "@sentry/react";

/*Services */
import {
  GetProspects,
  GetAcquisitionMethods,
  GetProgram,
  GetArchivedProspects,
  DownloadImportProspectsTemplate,
} from "../../api/Crm";

/** Elements */
import ContentSolid from "../../components/global/ContentSolid";
import { Table } from "../../components/table/Table";
import ContentTable from "../../components/table/ContentTable";
import SelectFilter from "../../components/table/SelectFilter";
import ModalCrmForm from "../../pages/crm/ModalCrmForm";
import ModalAddProspect from "../../pages/crm/ModalAddProspect";
import ModalChangeStatusLead from "../../pages/crm/ModalChangeStatusLead";
import ModalOperationNoAllowed from "../../pages/crm/ModalOperationNotAllowed";
import ReactToPrint from "react-to-print";
import ExcellentExport from "excellentexport";
import { LoadingTable } from "../../components/lazyLoading/LazyLoading";
import Label from "../../components/table/Label";
import {
  getUrlParamValue,
  goTo,
  goToHref,
  validateUserRoleIds,
} from "../../components/global/GlobalTools";
import DateRangeFilter from "../../components/table/DateRangeFilter";
import InputFilter from "../../components/table/InputFilter";
import { MainContext } from "../../App";
import SchoolCycle from "../../components/global/form/SchoolCycle";
import UnfileModal from "./UnfileModal";
import ModalImportProspects from "./ModalImportProspects";

/**Get param to open addProspect modal */
const addProspectParam = getUrlParamValue("addProspect");
/**Get param to open crm Form modal */
const crmFormParam = getUrlParamValue("crmForm");
//Custom styles section
const stylesCustom = {
  textDangerBold: {
    color: "#ea2c54",
    fontWeight: "bold",
    fontSize: "small",
  },
  textGrayBold: {
    color: "#c8cbcc",
    fontWeight: "bold",
    fontSize: "small",
  },
  colorTable: {
    textDecoration: "none",
    color: "#575757",
  },
};
/**
 *
 * Save selected cycle
 */
const obtainFilterCycle = () => {
  let cycle = null;
  //Get the last cycle saved
  let cycleStringify = localStorage.getItem("filterCycleProspect");
  if (cycleStringify) {
    //parse last cycle
    let cycleProspect = cycleStringify && JSON.parse(cycleStringify);
    cycle = cycleProspect;
    //  Clean item in local storage
    localStorage.removeItem("filterCycleProspect");
  }
  return cycle;
};

const ProspectListView = () => {
  const { permissions } = useContext(MainContext);
  const prefix = process.env.REACT_APP_PREFIX;
  const [t] = useTranslation(["crm", "global", "table", "reports"]);
  const [showEditColumns, setShowEditColumns] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showModalCrmForm, setShowModalCrmForm] = useState(crmFormParam);
  const [showModalAddProspect, setshowModalAddProspect] =
    useState(addProspectParam);
  const [showModalChangeStatusLead, setShowModalChangeStatusLead] =
    useState(false);
  const [showModalOperationNotAllowed, setShowModalOperationNotAllowed] =
    useState(false);
  const [statusToChange, setStatusToChange] = useState(null);
  const [rowIsSelect, setRowIsSelect] = useState(false);
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [printExport, setPrintExport] = useState(true);
  const [error, setError] = useState("");
  const [leads, setLeads] = useState([]);
  const [loadingView, setLoadingView] = useState(true);
  const [tabSelect] = useState("leads");
  const componentRef = useRef(); //Create the reference for printing
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [filters, setFilters] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [valuesOfMethods] = useState([
    {
      value: "",
      label: "",
      id: "",
    },
  ]);
  const [columnsData, setColumnsData] = useState({
    listProspect: false,
    adquisition: false,
    programFilters: false,
  });
  const [methodOptions, setMethodOptions] = useState(valuesOfMethods);
  const [methodOptionsProgram, setMethodOptionsProgram] =
    useState(valuesOfMethods);
  const [selectAllRows, setSelectAllRows] = useState({
    switch: false,
    value: false,
  });
  //Options to export to excel
  const exportOptions = {
    openAsDownload: true,
    format: "xlsx", //'xlsx' or 'xls' or 'csv'
    filename: t("CRM Report"),
  };
  const [readyLanguages, setReadyLanguages] = useState(false);
  const [count, setCount] = useState(0);
  const [showModalUnfile, setShowModalUnfile] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [filterCycle, setFilterCycle] = useState(obtainFilterCycle);
  const [acquisitionMethodsData, setAcquisitionMethodsData] = useState([]);
  const organizationServices = JSON.parse(
    localStorage.getItem(`${prefix}Services`)
  );
  const haveActiveCrmService = organizationServices.CRM.active;
  const [hasCycle, sethasCycle] = useState(false);

  /**
   * Stores the table column data in memory, and is not updated unless useMemo identifies a change in the data.
   */
  const columns = React.useMemo(
    () => [
      {
        Header: t("crm:tableCrm.name"),
        accessor: "name",
        Filter: (props) => <InputFilter {...props} />,
        minWidth: 150,
      },
      {
        Header: t("crm:tableCrm.phone"),
        accessor: "phone",
        Filter: (props) => <InputFilter {...props} />,
        minWidth: 150,
      },
      {
        Header: t("reports:headerTable.email"),
        accessor: "email",
        Filter: (props) => <InputFilter {...props} />,
        Cell: ({ cell: { value } }) => {
          return (
            <td style={{ wordBreak: "break-word", width: "100%" }}>
              {value ? value : ""}
            </td>
          );
        },
        minWidth: 150,
      },
      {
        Header: t("crm:tableCrm.statusProspect"),
        accessor: "status",
        Filter: (props) => (
          <SelectFilter defaultValues={statusOptions} {...props} />
        ),
        minWidth: 150,
      },
      {
        Header: t("crm:tableCrm.acquisition"),
        accessor: "acquisition_date",
        Filter: (props) => (
          <DateRangeFilter
            getByDateRange={filterHandler}
            lastNDays={{
              start_date: moment().subtract(30, "day").startOf("day").unix(),
              end_date: moment().endOf("day").unix(),
              label: t("reports:status.lastThirtyDay"),
              selectedByDefault: true,
            }}
            {...props}
          />
        ),
        filter: "dateBetween",
        minWidth: 150,
      },
      {
        Header: t("crm:tableCrm.method"),
        accessor: "acquisition_method",
        Filter: (props) => (
          <SelectFilter defaultValues={methodOptions} {...props} />
        ),
        minWidth: 150,
      },
      {
        Header: t("crm:tableCrm.converting"),
        accessor: "conversion_date",
        Filter: (props) => (
          <DateRangeFilter
            getByDateRange={filterHandler}
            optionNoSelection={true}
            {...props}
          />
        ),
        filter: "dateBetween",
        minWidth: 150,
      },
      {
        Header: t("modalAcademicView.textAcademicLevel"),
        accessor: "school_level",
        Filter: (props) => <SelectFilter {...props} />,
        minWidth: 150,
      },
      {
        Header: t("crm:tableCrm.program"),
        accessor: "program",
        Filter: (props) => (
          <SelectFilter defaultValues={methodOptionsProgram} {...props} />
        ),
        minWidth: 150,
      },
      {
        Header: t("crm:tableCrm.crmTasks"),
        accessor: "number_of_tasks",
        typeElement: "label",
        Cell: ({ cell: { value } }) => {
          return (
            <div style={{ textAlign: "center" }}>
              <Label
                values={value}
                bg={value > 0 ? "info" : "secondary"}
                text={"dark"}
                style={{ width: "auto" }}
              />
            </div>
          );
        },
        Filter: (props) => <InputFilter {...props} />,
        minWidth: 150,
      },
    ],
    [loading]
  );
  /**
   * Purpose: To obtain the cycle acquisition methods
   */
  const getAdquisition = () => {
    GetAcquisitionMethods().then((result) => {
      if (result && result.description) {
        Sentry.captureException(Error(JSON.stringify(result)));
        setError(result.description);
      } else {
        let data = result.data;
        let methods = [];
        data.forEach((item) => {
          methods.push({ label: item.name, id: item.name, value: item.id });
        });
        setMethodOptions(methods);
        columnsData.adquisition = true;
        setColumnsData(columnsData);
        loadColumns(columnsData);
        setAcquisitionMethodsData(data);
      }
    });
  };

  /**
   *Purpose: To obtain the organization's programs
   */
  const getProgramFilters = () => {
    GetProgram().then((result) => {
      if (result && result.description) {
        Sentry.captureException(Error(JSON.stringify(result)));
        setError(result.description);
      } else {
        let data = result.data;
        let methosProgram = [];
        data.forEach((item) => {
          methosProgram.push({
            label: item.name + " (" + item.school_level_name + ")",
            id: item.name,
            value: item.id,
          });
        });
        setMethodOptionsProgram(methosProgram);
        columnsData.programFilters = true;
        setColumnsData(columnsData);
        loadColumns(columnsData);
      }
    });
  };

  /**
   * Purpose: To manage where the filters come from to make the request.
   * @param {Obj} event  // Event containing the value of the selected filter
   * @param {Obj} column // Contains the object of the filter/column origin
   */
  const filterHandler = (event, column = false) => {
    let value = null;
    if (event.target) {
      event.target.value != "" ? (value = event.target.value) : null;
    } else if (event.valueOption) {
      value = event.valueOption;
    } else {
      value = event.value;
    }
    let origen = column.id;
    let filters = defaultFilters;
    switch (origen) {
      case "status":
        value != null ? (filters.status = value) : delete filters.status;
        break;
      case "name":
        value != null ? (filters.name = value) : delete filters.name;
        break;
      case "phone":
        value != null ? (filters.phone = value) : delete filters.phone;
        break;
      case "email":
        value != null ? (filters.email = value) : delete filters.email;
        break;
      case "acquisition_method":
        value != null
          ? (filters.acquisition_method_id = value)
          : delete filters.acquisition_method_id;
        break;
      case "school_level":
        value != null
          ? (filters.school_level_id = value)
          : delete filters.school_level_id;
        break;
      case "program":
        value != null
          ? (filters.program_id = value)
          : delete filters.program_id;
        break;
      case "number_of_tasks":
        value != null
          ? (filters.number_of_tasks = value)
          : delete filters.number_of_tasks;
        break;
      case "acquisition_date":
        if (event.start_date) {
          filters.acquisition_start_date = event.start_date;
          filters.acquisition_end_date = event.end_date;
        } else {
          delete filters.acquisition_start_date;
          delete filters.acquisition_end_date;
        }
        break;
      case "conversion_date":
        if (event.start_date) {
          filters.conversion_start_date = event.start_date;
          filters.conversion_end_date = event.end_date;
        } else {
          delete filters.conversion_start_date;
          delete filters.conversion_end_date;
        }
        break;
      default:
    }
    setDefaultFilters(filters);
    const cycleStorage = localStorage.getItem(`cycleCrmStorage${prefix}`);
    const showArchivedStorage = localStorage.getItem(
      `showArchivedLead${prefix}`
    );
    if (showArchivedStorage && showArchivedStorage != "false") {
      listArchivedProspect(cycleStorage, filters);
    } else {
      listProspect(cycleStorage, filters);
    }
  };

  /**
   * Used to clean selected filters
   */
  const cleanRequestFilters = () => {
    let filters = {};
    listProspect(filters);
  };

  /**
   * Obtain complete information of the selected cycle
   * @param {int} cycleId
   */
  const getCycleSelectedInfo = (cycleId) => {
    const cycles = JSON.parse(localStorage.getItem(`cycles${prefix}`));
    const hasCycles = cycles && Array.isArray(cycles) && cycles.length;
    if (hasCycles) {
      const cycleSelected = cycles.find((cycle) => cycle.id == cycleId);
      setFilterCycle(cycleSelected);
    } else {
      setTimeout(() => {
        getCycleSelectedInfo();
      }, 1000);
    }
  };

  /**
   * Get the list of all prospects
   */
  const listProspect = (cycle, request = defaultFilters) => {
    let schoolCycleId = cycle && cycle.id ? cycle.id : cycle;
    request.school_cycle_id = schoolCycleId;
    request.limit = 0; // Set limit 0 to get all prospects based on filters
    localStorage.setItem(`cycleCrmStorage${prefix}`, request.school_cycle_id); // set current cycle value
    setLoading(true);
    cycle && getCycleSelectedInfo(schoolCycleId);
    GetProspects(request).then((result) => {
      if (result && result.description) {
        Sentry.captureException(Error(JSON.stringify(result)));
        setError(result.description);
      } else {
        let leads = result.data;
        let methodSchoolLevel = [];
        for (let item of leads) {
          item.index = item.id;
          item.linkToPage = `${process.env.REACT_APP_V1}/crm/lead_profile/${item.index}`;
          let dateMoment = moment.utc(item.acquisition_date);
          item.acquisition_date = moment(dateMoment)
            .local()
            .format("DD/MM/YYYY");
          let conversionDateMoment = moment.utc(item.conversion_date);
          item.conversion_date = moment(conversionDateMoment)
            .local()
            .format("DD/MM/YYYY");
          for (let param in item) {
            if (param === "status") {
              switch (item[param]) {
                case "contact":
                  item[param] = t("tableCrm.textContact");
                  break;
                case "possible_client":
                  item[param] = t("table:debtReport.possibleClient");
                  break;
                case "Active":
                  item[param] = t("table:debtReport.student");
                  break;
                default:
              }
            }
            item[param] =
              item[param] != null && item[param] != "" ? item[param] : "N/A";
          }
          if (item.school_level !== "N/A") {
            methodSchoolLevel.push({
              label: item.school_level,
              id: item.school_level,
              value: item.id,
            });
          }
        }
        //Filter prospects inactives
        leads = leads.filter((lead) => lead.status !== "Inactive");
        //Save prospects
        setLeads(leads);
        //Remove repeated school levels
        methodSchoolLevel = filterArray(methodSchoolLevel);
      }
      //Add status options in the filter
      setStatusOptions([
        {
          value: "contact",
          label: t("tableCrm.textContact"),
          id: t("tableCrm.textContact"),
        },
        {
          value: "possible_client",
          label: t("table:debtReport.possibleClient"),
          id: t("table:debtReport.possibleClient"),
        },
        {
          value: "Active",
          label: t("table:debtReport.student"),
          id: t("table:debtReport.student"),
        },
        {
          value: "suspended",
          label: t("table:debtReport.suspended"),
          id: t("table:debtReport.suspended"),
        },
        {
          value: "unenrolled",
          label: t("table:debtReport.unenrolled"),
          id: t("table:debtReport.unenrolled"),
        },
      ]);
      columnsData.listProspect = true;
      setColumnsData(columnsData);
      loadColumns(columnsData);
      sethasCycle(true);
    });
  };

  /**
   * Function for the checkbox switch where it shows the archived leads
   * or leads in general according to the selected cycle
   */
  const showArchivedProspect = (value) => {
    const cycleStorage = localStorage.getItem(`cycleCrmStorage${prefix}`);
    value ? listArchivedProspect(cycleStorage) : listProspect(cycleStorage);
    setShowArchived(value);
    localStorage.setItem(`showArchivedLead${prefix}`, value);
  };

  /**
   * Get the list of all archived prospects
   */
  const listArchivedProspect = (cycleId, request = defaultFilters) => {
    request.school_cycle_id = cycleId;
    request.limit = 0;
    localStorage.setItem(`cycleCrmStorage${prefix}`, cycleId); // set current cycle value
    setLoading(true);
    GetArchivedProspects(request).then((result) => {
      if (result && result.description) {
        Sentry.captureException(Error(JSON.stringify(result)));
        setError(result.description);
      } else {
        let leadsArchive = result.data;
        let methodSchoolLevel = [];
        for (let item of leadsArchive) {
          item.index = item.id;
          let dateMoment = moment.utc(item.acquisition_date);
          item.acquisition_date = moment(dateMoment)
            .local()
            .format("DD/MM/YYYY");
          let conversionDateMoment = moment.utc(item.conversion_date);
          item.conversion_date = moment(conversionDateMoment)
            .local()
            .format("DD/MM/YYYY");
          for (let param in item) {
            if (param === "status") {
              switch (item[param]) {
                case "contact":
                  item[param] = t("tableCrm.textContact");
                  break;
                case "possible_client":
                  item[param] = t("table:debtReport.possibleClient");
                  break;
                case "Active":
                  item[param] = t("table:debtReport.student");
                  break;
                default:
              }
            }
            item[param] =
              item[param] != null && item[param] != "" ? item[param] : "N/A";
          }
          if (item.school_level !== "N/A") {
            methodSchoolLevel.push({
              label: item.school_level,
              id: item.school_level,
              value: item.id,
            });
          }
        }
        //Filter prospects inactives
        leadsArchive = leadsArchive.filter(
          (lead) => lead.status !== "Inactive"
        );
        //Save prospects
        setLeads(leadsArchive);
        //Remove repeated school levels
        methodSchoolLevel = filterArray(methodSchoolLevel);
      }
      //Add status options in the filter
      setStatusOptions([
        {
          value: "contact",
          label: t("tableCrm.textContact"),
          id: t("tableCrm.textContact"),
        },
        {
          value: "possible_client",
          label: t("table:debtReport.possibleClient"),
          id: t("table:debtReport.possibleClient"),
        },
        {
          value: "Active",
          label: t("table:debtReport.student"),
          id: t("table:debtReport.student"),
        },
      ]);
      columnsData.listProspect = true;
      setColumnsData(columnsData);
      loadColumns(columnsData);
    });
  };
  /**
   * Description: Function to filter elements repeated by id
   */
  const filterArray = (Array) => {
    const element = {};
    return Array.filter((current) =>
      element[current.id] ? false : (element[current.id] = true)
    );
  };
  /**
   * Saves the data for the table in memory, and is not updated unless useMemo identifies a change in the data
   */
  const data = React.useMemo(() => leads, [loading]);
  /**
   * Initial loading
   */
  useEffect(() => {
    Sentry.setTag("section", "CRM Prospects");
    let dataFilter = JSON.parse(localStorage.getItem("filtersProspect"));
    let dataHiddenColumns = JSON.parse(
      localStorage.getItem("hiddenColumnsProspect")
    );
    if (dataFilter) {
      setFilters(dataFilter);
      localStorage.removeItem("filtersProspect");
    }
    if (dataHiddenColumns) {
      setHiddenColumns(dataHiddenColumns);
      localStorage.removeItem("hiddenColumnsProspect");
    }
    localStorage.setItem(`showArchivedLead${prefix}`, false);
    getAdquisition();
    getProgramFilters();
  }, []);

  /**
   * Description: Effect to know if all prospects are selected
   */
  useEffect(() => {
    let selectAll = {
      switch: selectAllRows.switch,
      value: isAllRowsSelected,
    };
    setSelectAllRows(selectAll);
  }, [selectedRowIds]);

  /**
   * Detects when column data is complete and sends the table to render.
   * @param {Object} columnsData
   */
  const loadColumns = (columnsData) => {
    const params = Object.keys(columnsData);
    const found = params.find((element) => columnsData[element] === false);
    if (found == undefined && typeof t == "function") {
      setLoading(false);
      setTimeout(setLoadingView(false), 500);
    }
  };

  /**
   * row select
   */
  const rowSelect = (value) => {
    setRowIsSelect(value);
  };

  //Export to Excel
  const downloadFile = (exportOptions, exportData) => {
    ExcellentExport.convert(exportOptions, exportData);
  };

  /**
   * Stock options for PDF or Excel downloading
   */
  const actionOptions = [
    { value: "list", label: "reports:actionOptions.downloadPDF" },
    { value: "excel", label: "reports:actionOptions.downloadExcel" },
  ];

  /**
   * Purpose: Redirect to prospect list, tasks and activities in CRM
   * @param {String} tabView
   */
  const goApp1 = (tabView) => {
    var now = new Date();
    now.setDate(now.getDate() + 5);
    goToHref(`/crm/${tabView}`);
  };

  /**
   * Purpose: Action when you click on a table row
   * @param {Obj} lead // It is an object with the parameters of the prospectus
   * @param {Obj} state //Object that stores the status of filters and hidden columns.
   */
  const rowOnclick = (lead, state) => {
    let filters = state.filters;
    let hiddenColumns = state.hiddenColumns;
    localStorage.setItem("filtersProspect", JSON.stringify(filters));
    localStorage.setItem("filterCycleProspect", JSON.stringify(filterCycle)); //save the current cycle in the local storage
    localStorage.setItem(
      "hiddenColumnsProspect",
      JSON.stringify(hiddenColumns)
    );
    goApp1("lead_profile/" + lead.id);
  };

  /**
   * Purpose: Function to show modal for change status
   * @param {string} status - "contact" | "archived"
   */
  const changeStatusHandler = (status) => {
    setStatusToChange(status);
    setShowModalChangeStatusLead(true);
  };

  // Effect for loading initial translations
  useEffect(() => {
    if (typeof t == "function") {
      setTimeout(() => {
        setReadyLanguages(true), loadColumns(columnsData);
      }, 500);
    }
  }, [t]);

  /**
   * Description: Function to stop loading when the organization has no cycles
   */
  const stopLoading = () => {
    setLoading(false);
    setLoadingView(false);
    sethasCycle(false);
  };

  //View
  return haveActiveCrmService && permissions.crm_prospects.access ? (
    <Container fluid>
      <Row>
        <Col md={12} lg={2}>
          <h2>{"CRM"}</h2>
        </Col>
        <Col md={12} lg={2} xs={12}>
          {/** Filter Select School Cycle */}
          <SchoolCycle
            useIn="filters"
            handleOnchange={(e) => {
              showArchived
                ? listArchivedProspect(e?.id || null)
                : listProspect(e?.id || null); //e?.id It is a simplified ternary where it looks for id when e exists.
            }}
            value={filterCycle}
            selectedByDefault={true}
            getDefaultValues={listProspect}
            notCycles={stopLoading}
          />
        </Col>
      </Row>

      <Tab.Container id="left-tabs-example" defaultActiveKey="crm">
        <ContentSolid
          width="fit-content"
          className="d-none d-lg-block d-xl-block d-xxl-block"
        >
          <Nav
            variant="pills"
            defaultActiveKey="leads"
            activeKey="leads"
            style={{ width: "fit-content", margin: "0 auto" }}
          >
            <Nav.Item>
              {permissions.crm_tasks.access && (
                <a
                  href={`${process.env.REACT_APP_V1}/crm/tasks`}
                  style={{ textDecoration: "none" }}
                >
                  <Nav.Link eventKey="task" onClick={() => goApp1("tasks")}>
                    {readyLanguages ? t("viewProspectList.tasks") : null}
                  </Nav.Link>
                </a>
              )}
            </Nav.Item>
            {permissions.crm_prospects.access && (
              <a href="/crm/leads" style={{ textDecoration: "none" }}>
                <Nav.Item>
                  <Nav.Link eventKey="leads" active={tabSelect == "leads"}>
                    {readyLanguages ? t("viewProspectList.prospectList") : null}
                  </Nav.Link>
                </Nav.Item>
              </a>
            )}
            {permissions.crm_activities.access && (
              <Nav.Item>
                <a
                  href={`${process.env.REACT_APP_V1}/crm/activities`}
                  style={{ textDecoration: "none" }}
                >
                  <Nav.Link
                    eventKey="activity"
                    onClick={() => goApp1("activities")}
                  >
                    {readyLanguages ? t("viewProspectList.activity") : null}
                  </Nav.Link>
                </a>
              </Nav.Item>
            )}
          </Nav>
        </ContentSolid>
      </Tab.Container>

      {/** Section for print, export, modal buttons */}
      <Row style={{ marginBottom: "20px" }}>
        <Col xs={6} lg={6}>
          <div className="mt-1">
            {permissions.crm_prospects.edit && (
              <Button
                variant="outline-secondary"
                onClick={() => {
                  let selectAll = {
                    switch: !selectAllRows.switch,
                    value: !selectAllRows.value,
                  };
                  setSelectAllRows(selectAll);
                }}
                style={{ marginRight: "10px" }}
                disabled={!count}
              >
                {readyLanguages
                  ? t(
                      !isAllRowsSelected
                        ? "global:buttons.selectAll"
                        : "global:buttons.unselectAll"
                    )
                  : null}
              </Button>
            )}
            {(permissions.crm_prospects.edit ||
              permissions.crm_prospects.delete) && (
              <Dropdown
                style={
                  rowIsSelect && !showArchived
                    ? { display: "inline-block", marginRight: "10px" }
                    : { display: "none" }
                }
              >
                <Dropdown.Toggle
                  id="dropdown-button-dark-example1"
                  variant="outline-secondary"
                  disabled={printExport}
                >
                  {readyLanguages ? t("global:buttons.action") : null}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {permissions.crm_prospects.edit && (
                    <Dropdown.Item
                      onClick={() => changeStatusHandler("archived")}
                    >
                      {readyLanguages ? t("global:archiveProspect") : null}
                    </Dropdown.Item>
                  )}
                  {validateUserRoleIds([1, 2]) && (
                    <Dropdown.Item
                      onClick={() => changeStatusHandler("contact")}
                    >
                      {readyLanguages ? t("converToContact") : null}
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            )}
            {/*condition the unarchive button for when the api is concentrated  */}
            {rowIsSelect && showArchived && (
              <Button
                variant="outline-secondary"
                onClick={() => setShowModalUnfile(true)}
              >
                {t("modalUnfile.titleUnfileBtn")}
              </Button>
            )}
            <p className="ms-3 d-inline" style={stylesCustom.textGrayBold}>
              {t("viewProspectList.showArchived")}
            </p>
            <FormCheck className="ms-1 d-inline form-switch">
              <FormCheck.Input
                id="custom-switch"
                type="checkbox"
                style={{ marginTop: "6px" }}
                onClick={() => showArchivedProspect(!showArchived)}
                checked={showArchived}
              />
            </FormCheck>
            {/* Total counter of records and selected records in Prospect table */}
            {selectedRowIds.length ? (
              selectedRowIds.length == 1 ? (
                <p
                  className="ms-3 d-inline"
                  style={stylesCustom.textDangerBold}
                >
                  {t("table:textTableViews.selectedRecord", {
                    countSelected: selectedRowIds.length,
                  })}
                </p>
              ) : (
                <p
                  className="ms-3 d-inline"
                  style={stylesCustom.textDangerBold}
                >
                  {t("table:textTableViews.selectedRecords", {
                    countSelected: selectedRowIds.length,
                  })}
                </p>
              )
            ) : (
              <p className="ms-3 d-inline" style={stylesCustom.textGrayBold}>
                {t("table:textTableViews.totalRecords", { count: count })}
              </p>
            )}
          </div>
        </Col>
        <Col xs={6} lg={6}>
          <div style={{ paddingTop: "5px", float: "right" }}>
            <Dropdown style={{ display: "inline-block", marginRight: "10px" }}>
              <Dropdown.Toggle
                id="dropdown-button-dark-example1"
                variant="outline-secondary"
                disabled={printExport}
              >
                <i className="bi bi-download"></i>
                {rowIsSelect
                  ? t("global:buttons.selectTable")
                  : t("global:buttons.download")}
              </Dropdown.Toggle>
              <Dropdown.Menu disabled={printExport}>
                {actionOptions.map((action) => {
                  return action.value == "list" ? (
                    <ReactToPrint
                      trigger={() => (
                        <Dropdown.Item>{t(action.label)}</Dropdown.Item>
                      )}
                      content={() => componentRef.current}
                    />
                  ) : (
                    <ReactToPrint
                      trigger={() => (
                        <Dropdown.Item>{t(action.label)}</Dropdown.Item>
                      )}
                      content={() => downloadFile(exportOptions, exportData)}
                    />
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            {/**Import prospects */}
            {permissions.crm_prospects.create && (
              <ModalImportProspects
                downloadTemplate={DownloadImportProspectsTemplate}
                thereAreNoCycles={!hasCycle}
              />
            )}
            {permissions.crm_prospects.create && (
              <a
                href="?crmForm=true"
                onClick={(e) => {
                  e.preventDefault();
                }}
                style={{ cursor: !hasCycle && "default" }}
              >
                <Button
                  variant="outline-secondary"
                  onClick={() => setShowModalCrmForm(true)}
                  style={{ marginRight: "10px" }}
                  disabled={!hasCycle}
                >
                  <i
                    className="bi bi-card-checklist"
                    style={{ fontSize: "15px" }}
                  ></i>
                  {readyLanguages ? t("viewProspectList.crmForm") : null}
                </Button>
              </a>
            )}
            {permissions.crm_prospects.create && (
              <a
                href="?addProspect=true"
                onClick={(e) => {
                  e.preventDefault();
                }}
                style={{ cursor: !hasCycle && "default" }}
              >
                <Button
                  variant="primary"
                  onClick={() => setshowModalAddProspect(true)}
                  disabled={!hasCycle}
                >
                  <i
                    className="bi bi-person-plus-fill"
                    style={{ fontSize: "15px" }}
                  ></i>
                  {readyLanguages ? t("modalAddProspect.addProspect") : null}
                </Button>
              </a>
            )}
          </div>
        </Col>
      </Row>
      {loadingView ? (
        <ContentSolid style={{ marginTop: "20px" }}>
          <LoadingTable />
        </ContentSolid>
      ) : (
        <>
          {error}
          {/** Table */}
          {/*The startColumFilter and lastColumFilter props indicates in which column the box with the magnifying glass should appear in the filters*/}
          <ContentTable startColumFilter={1} lastColumFilter={7}>
            <Table
              ref={componentRef}
              rowSelect={true}
              data={data}
              columns={columns}
              loading={loading}
              summation={false} //Indicates if there will be a row of sums
              setExportData={setExportData}
              setPrintExport={setPrintExport}
              showEditColumns={showEditColumns} //Flag for the modal "Edit List Columns"
              setShowEditColumns={setShowEditColumns} //Toggle function of the "Edit List Columns" modal
              rowIsSelect={rowSelect}
              filters={filters}
              hiddenColumns={hiddenColumns}
              cleanRequestFilters={cleanRequestFilters}
              rowOnclick={rowOnclick}
              selectAllRows={selectAllRows}
              setSelectedRowIds={setSelectedRowIds}
              setIsAllRowsSelected={setIsAllRowsSelected}
              setRowsDisplayed={setCount}
              isLinked={true}
            />
          </ContentTable>
        </>
      )}

      <ModalCrmForm
        showModalCrmForm={showModalCrmForm}
        setShowModalCrmForm={setShowModalCrmForm}
      />
      {showModalAddProspect && (
        <ModalAddProspect
          showModalAddProspect={showModalAddProspect}
          setshowModalAddProspect={setshowModalAddProspect}
          acquisitionMethodsData={acquisitionMethodsData}
        />
      )}
      <ModalChangeStatusLead
        showModalChangeStatusLead={showModalChangeStatusLead}
        setShowModalChangeStatusLead={setShowModalChangeStatusLead}
        statusToChange={statusToChange}
        selectedRowIds={selectedRowIds}
        setShowModalOperationNotAllowed={setShowModalOperationNotAllowed}
        listProspect={listProspect}
      />
      <ModalOperationNoAllowed
        showModalOperationNotAllowed={showModalOperationNotAllowed}
        setShowModalOperationNotAllowed={setShowModalOperationNotAllowed}
        statusToChange={statusToChange}
      />
      <UnfileModal
        showModalUnfile={showModalUnfile}
        setShowModalUnfile={setShowModalUnfile}
        selectedRowIds={selectedRowIds}
        listArchivedProspect={listArchivedProspect}
      />
    </Container>
  ) : (
    goTo("/dashboard")
  );
};

export default ProspectListView;

ProspectListView.propTypes = {
  cell: PropTypes.func,
  props: PropTypes.obj,
  row: PropTypes.obj,
  state: PropTypes.obj,
};
